.box {
  padding: 12px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;

  label {
    margin-top: 8px;
  }
}

.tableButton {
	display: block;
	color: #40a9ff;
	cursor: pointer;
	transition: all 0.3s;
	&:hover {
		color:#026bc2;
	}
}

.divider {
  margin: 12px 0px;
}

.wrapButton {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-column-gap: 16px;
  justify-content: space-between;
}

.reportTitle {
	cursor: pointer;
	transition: all 0.3s;
	&:hover {
		color: #40a9ff;
	}
}

.table {
	:global {
		table, td, .ant-table-container {
			border-color: black !important;
		}
		.fizColumn {
			background-color: #e1efda !important;
		}

		.ant-table-thead .yrColumn {
			color: #FF0000 !important;
			background-color: white !important;
		}
		th {
			font-weight: bolder !important;
			border-color: black !important;
			background-color: #e1efda !important;
		}
		.summaryColumn {
			font-weight: bolder !important;
		}
	}
}