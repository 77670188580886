.error {
  color: #ff4d4f;
  font-size: 0.9em;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input-error {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
