.addButton {
  margin-bottom: 16px;
}

.select {
  width: 100%;
}

.search {
  margin-left: 20px;
  width: 350px;
}