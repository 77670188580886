.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.datePicker {
  width: 100%;
}

.subtitle {
  margin-top: 16px;
  margin-left: 12px;
}

.row {
  height: 60px;
}

.rowHead {
  background-color: #dbdbdb;
  height: 70px;
}

.localitySelect {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.wrapRoutesTable {
  margin: 30px 0px;
}

.button {
  margin-top: 16px;
}

.box {
  display: grid;
  grid-template-columns: 30% 20% 20% 8%;
  grid-column-gap: 16px;
  margin-bottom: 16px;
}

.rangePicker {
  width: 100%;
}

.select {
  width: 100%;
}

.popup {
  width: max-content;
}