@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.logo {
  float: left; }

.color-white {
  color: #fff; }

.map-wrap {
  height: 400px; }

.modal__fullwidth {
  width: 90% !important; }

.ymaps-2-1-72-map {
  height: 400px !important; }

.link {
  color: #40a9ff;
  cursor: pointer; }

.map-wrapper {
  margin: 20px 0;
  height: 80vh; }

#root .map-wrapper .ymaps-2-1-72-map {
  height: 80vh !important; }

body .ant-modal-body .ant-checkbox-group-item {
  padding: 5px;
  width: 48%;
  overflow: hidden;
  flex-grow: 1; }

.custom_checkbox_group {
  display: flex;
  flex-wrap: wrap; }
  .custom_checkbox_group .ant-checkbox + span {
    white-space: nowrap;
    display: inline-block;
    width: 90px; }

.button_bind button {
  width: 100%; }

.ant-collapse {
  min-height: 200px; }

.media .ant-radio-group,
.media label {
  width: 100%; }

.media .item {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  position: relative; }
  .media .item .anticon {
    position: absolute;
    font-size: 18px;
    top: 15px;
    right: 10px; }

.notification__status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto; }

.notification__status--success {
  border: 1px solid #006900;
  background-color: #00f30780; }

.notification__status--warning {
  border: 1px solid #ffc107;
  background-color: #ffc107; }

.notification__status--danger {
  border: 1px solid #b71c1c;
  background-color: #f44336; }

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #001529 !important; }

.ant-menu.ant-menu-dark .ant-menu-item-active {
  background-color: #40a9ff !important; }

thead .text-align-bottom {
  vertical-align: bottom; }

.gosuslugy .ant-input-number-handler-wrap {
  display: none; }

.gosuslugy tbody .ant-table-cell,
.gosuslugy thead .ant-table-cell {
  padding: 5px 5px; }

.gosuslugy th,
.gosuslugy td {
  box-sizing: border-box !important; }

.gosuslugy .table-3 .ant-table-row-level-0 td {
  background-color: #ffc48c !important; }

.gosuslugy .table-3 .ant-table-row-level-1 td {
  background-color: #effab4 !important; }

.gosuslugy .table-3 .ant-table-cell-with-append {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.ant-layout-sider-trigger {
  display: none; }

.SingleLayout_modalTitle__1HJZp {
  font-weight: 600; }

.SingleLayout_modalMonths__1EZoH {
  color: #026bc2; }

.SingleLayout_buttonWrapper__2-Q83 {
  text-align: center; }

.Error_title__lBmQ0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.FiltersChart_container__21UJR {
  display: flex;
  margin-bottom: 20px;
  max-width: 100%; }

.FiltersChart_maxSelect__HhmVg {
  width: 200px; }

.FiltersChart_minSelect__2JbmW {
  width: 100px; }

.FiltersChart_select__3iK7x {
  margin-right: 12px; }

.FiltersChart_searchButton__yPifX {
  margin-right: 12px; }

.ExcelButton_container__Vgls- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }

.ExcelButton_subtitle__Ek0my {
  margin: 0;
  padding: 0; }

.Chart_box__1mDUn {
  padding: 12px; }

.Chart_checkboxGroup__3sAVO {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto; }
  .Chart_checkboxGroup__3sAVO label {
    margin-top: 8px; }

.Chart_tableButton__2qvLX {
  display: block;
  color: #40a9ff;
  cursor: pointer;
  transition: all 0.3s; }
  .Chart_tableButton__2qvLX:hover {
    color: #026bc2; }

.Chart_divider__25gpy {
  margin: 12px 0px; }

.Chart_wrapButton__1yk4Z {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-column-gap: 16px;
  justify-content: space-between; }

.Chart_reportTitle__28ejf {
  cursor: pointer;
  transition: all 0.3s; }
  .Chart_reportTitle__28ejf:hover {
    color: #40a9ff; }

.Chart_table__3c6VE table, .Chart_table__3c6VE td, .Chart_table__3c6VE .ant-table-container {
  border-color: black !important; }

.Chart_table__3c6VE .fizColumn {
  background-color: #e1efda !important; }

.Chart_table__3c6VE .ant-table-thead .yrColumn {
  color: #FF0000 !important;
  background-color: white !important; }

.Chart_table__3c6VE th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #e1efda !important; }

.Chart_table__3c6VE .summaryColumn {
  font-weight: bolder !important; }

.ChartBuilder_table__jtfm9 table,
.ChartBuilder_table__jtfm9 td,
.ChartBuilder_table__jtfm9 .ant-table-container {
  border-color: black !important; }

.ChartBuilder_table__jtfm9 .fizColumn {
  background-color: #e1efda !important; }

.ChartBuilder_table__jtfm9 .ant-table-thead .yrColumn {
  color: #ff0000 !important;
  background-color: white !important; }

.ChartBuilder_table__jtfm9 .ant-table-tbody .yrRow .yrColumn {
  background-color: #ff0000 !important; }

.ChartBuilder_table__jtfm9 th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #e1efda !important; }

.ChartBuilder_table__jtfm9 .summaryColumn {
  font-weight: bolder !important; }

.ChartBuilder_error__KXXhW {
  color: #ff4d4f;
  font-size: 0.9em;
  -webkit-animation: ChartBuilder_fadeIn__2wizd 0.5s;
          animation: ChartBuilder_fadeIn__2wizd 0.5s; }

@-webkit-keyframes ChartBuilder_fadeIn__2wizd {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ChartBuilder_fadeIn__2wizd {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ChartBuilder_input-error__pEjUC {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.EditChart_error__2_8Y7 {
  color: #ff4d4f;
  font-size: 0.9em;
  -webkit-animation: EditChart_fadeIn___1P6N 0.5s;
          animation: EditChart_fadeIn___1P6N 0.5s; }

@-webkit-keyframes EditChart_fadeIn___1P6N {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes EditChart_fadeIn___1P6N {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.EditChart_input-error__g4fzr {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.ChartStatus_errorTitle__32hnz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh; }

.ChartStatus_table__1iWG5 .ant-table-tbody > tr:hover td {
  background-color: none !important; }

.FilterChartStatus_container__WxxU- {
  display: flex;
  margin-bottom: 20px;
  max-width: 100%; }

.FilterChartStatus_select__dhiFt {
  margin-right: 12px;
  width: 200px; }

.FilterChartStatus_maxSelect__5VWrq {
  width: 500px; }

.FilterChartStatus_minSelect__m2GnC {
  width: 100px; }

.FilterChartStatus_searchButton__35Uf0 {
  margin-right: 12px; }

.tabsStyles_addButton__RL_s- {
  margin-bottom: 16px; }

.tabsStyles_select__3Y0-e {
  width: 100%; }

.tabsStyles_search__P6any {
  margin-left: 20px;
  width: 350px; }

.ModalSettlements_inputNumber__2yiQX {
  width: 50%; }

.CreateDirectoryInterpreterTab_box__1UKAd {
  display: flex;
  justify-content: space-between; }

.PassengerTransport_pagination__3iKlp {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px; }

.PassengerTransport_datePicker__1BIxM {
  width: 100%; }

.PassengerTransport_subtitle__54QGm {
  margin-top: 16px;
  margin-left: 12px; }

.PassengerTransport_row__1at2A {
  height: 60px; }

.PassengerTransport_rowHead__blZjl {
  background-color: #dbdbdb;
  height: 70px; }

.PassengerTransport_localitySelect__1NGiz {
  width: 100%;
  overflow: hidden;
  position: relative; }

.PassengerTransport_wrapRoutesTable__2UPXc {
  margin: 30px 0px; }

.PassengerTransport_button__AAHc3 {
  margin-top: 16px; }

.PassengerTransport_box__b8XdS {
  display: grid;
  grid-template-columns: 30% 20% 20% 8%;
  grid-column-gap: 16px;
  margin-bottom: 16px; }

.PassengerTransport_rangePicker__2YOp5 {
  width: 100%; }

.PassengerTransport_select__a6ui4 {
  width: 100%; }

.PassengerTransport_popup__2H1ZX {
  width: -webkit-max-content;
  width: max-content; }

.UserActions_box__1_7Or {
  padding: 12px; }

.UserActions_checkboxGroup__hZsst {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto; }
  .UserActions_checkboxGroup__hZsst label {
    margin-top: 8px; }

.UserActions_divider__1FzQb {
  margin: 12px 0px; }

.UserActions_actions__3BiL8 {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.UserActions_actionObject__wfCfu {
  margin-bottom: 4px; }
  .UserActions_actionObject__wfCfu span {
    color: #40a9ff; }

.UserActions_errorTitle__14cD3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.FiltersChart_container__2xKFU {
  display: flex;
  margin-bottom: 20px;
  max-width: 100%; }

.FiltersChart_maxSelect__3lraN {
  width: 200px; }

.FiltersChart_minSelect__3dDul {
  width: 100px; }

.FiltersChart_select__3UzGp {
  margin-right: 12px; }

.FiltersChart_searchButton__1_v4b {
  margin-right: 12px; }

.ExcelButton_container__36I2F {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }

.ExcelButton_subtitle__2oUGz {
  margin: 0;
  padding: 0; }

.EditChart_error__5QTV3 {
  color: #ff4d4f;
  font-size: 0.9em;
  -webkit-animation: EditChart_fadeIn__I9mHs 0.5s;
          animation: EditChart_fadeIn__I9mHs 0.5s; }

@-webkit-keyframes EditChart_fadeIn__I9mHs {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes EditChart_fadeIn__I9mHs {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.EditChart_input-error__1YC3B {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.EditChart_table__1kj21 table,
.EditChart_table__1kj21 td,
.EditChart_table__1kj21 .ant-table-container {
  border-color: black !important; }

.EditChart_table__1kj21 .fizColumn {
  background-color: #e1efda !important; }

.EditChart_table__1kj21 .ant-table-thead .yrColumn {
  color: #ff0000 !important;
  background-color: white !important; }

.EditChart_table__1kj21 .ant-table-tbody .yrRow .yrColumn {
  background-color: #ff0000 !important; }

.EditChart_table__1kj21 th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #e1efda !important; }

.EditChart_table__1kj21 .summaryColumn {
  font-weight: bolder !important; }

.EditChart_error__5QTV3 {
  color: #ff4d4f;
  font-size: 0.9em;
  -webkit-animation: EditChart_fadeIn__I9mHs 0.5s;
          animation: EditChart_fadeIn__I9mHs 0.5s; }

@keyframes EditChart_fadeIn__I9mHs {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.EditChart_input-error__1YC3B {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); }

.form {
  display: flex;
  justify-content: space-around;
  grid-gap: 16px;
  gap: 16px; }
  .form__group {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px; }

.Chart_box__1Yo5O {
  padding: 12px; }

.Chart_checkboxGroup__18QID {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto; }
  .Chart_checkboxGroup__18QID label {
    margin-top: 8px; }

.Chart_tableButton__1eHWU {
  display: block;
  color: #40a9ff;
  cursor: pointer;
  transition: all 0.3s; }
  .Chart_tableButton__1eHWU:hover {
    color: #026bc2; }

.Chart_divider__1DM_k {
  margin: 12px 0px; }

.Chart_wrapButton__2QJeR {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-column-gap: 16px;
  justify-content: space-between; }

.Chart_reportTitle__BvyUH {
  cursor: pointer;
  transition: all 0.3s; }
  .Chart_reportTitle__BvyUH:hover {
    color: #40a9ff; }

.Chart_table__fMeJV table, .Chart_table__fMeJV td, .Chart_table__fMeJV .ant-table-container {
  border-color: black !important; }

.Chart_table__fMeJV .fizColumn {
  background-color: #e1efda !important; }

.Chart_table__fMeJV .ant-table-thead .yrColumn {
  color: #FF0000 !important;
  background-color: white !important; }

.Chart_table__fMeJV th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #e1efda !important; }

.Chart_table__fMeJV .summaryColumn {
  font-weight: bolder !important; }

.Chart_modalStyles__KkUcX {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.Chart_payAttentionHeaderText__rlycU {
  font-size: large;
  margin-bottom: 30px; }

.Chart_payAttentionText__1F3Co {
  margin: 0px 30px;
  margin-bottom: 30px;
  text-align: center; }

.container-selectors {
  display: flex;
  grid-gap: 12px;
  gap: 12px; }
  .container-selectors .ant-form-item {
    flex: 1 1; }

.container-buttons {
  display: flex;
  grid-gap: 8px;
  gap: 8px; }

.container-table table,
.container-table td,
.container-table .ant-table-container {
  border-color: black !important; }

.container-table thead .ant-table-cell,
.container-table tbody .ant-table-cell {
  padding: 4px; }

.container-table .table-events .ant-table-row-level-0 td {
  background-color: #ffc48c !important; }

.container-table .table-events .ant-table-row-level-1 td {
  background-color: #effab4 !important; }

.container-table .table-events .ant-table-cell-with-append {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.container-table .fizColumn {
  background-color: #ddebf7 !important; }

.container-table .refusalColumn {
  background-color: #9bc2e6 !important; }

.container-table .ant-table-thead .yrColumn {
  color: #ff0000 !important;
  background-color: white !important; }

.container-table .ant-table-tbody .yrRow .yrColumn {
  background-color: #ff0000 !important; }

.container-table th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #ddebf7 !important; }

.container-table .summaryColumn {
  font-weight: bolder !important; }

.container-table .wrapper-buttons {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 12px; }

.form {
  display: flex;
  justify-content: space-around;
  grid-gap: 16px;
  gap: 16px; }
  .form__group {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px; }

.container-selectors {
  display: flex;
  grid-gap: 12px;
  gap: 12px; }
  .container-selectors .ant-form-item {
    flex: 1 1; }

.container-buttons {
  display: flex;
  grid-gap: 8px;
  gap: 8px; }

.dropdown-render {
  padding: 4px 8px 8px 8px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0; }

.container-table table,
.container-table td,
.container-table .ant-table-container {
  border-color: black !important; }

.container-table thead .ant-table-cell,
.container-table tbody .ant-table-cell {
  padding: 4px; }

.container-table .table-events .ant-table-row-level-0 td {
  background-color: #ffc48c !important; }

.container-table .table-events .ant-table-row-level-1 td {
  background-color: #effab4 !important; }

.container-table .table-events .ant-table-cell-with-append {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.container-table .fizColumn {
  background-color: #ddebf7 !important; }

.container-table .refusalColumn {
  background-color: #9bc2e6 !important; }

.container-table .ant-table-thead .yrColumn {
  color: #ff0000 !important;
  background-color: white !important; }

.container-table .ant-table-tbody .yrRow .yrColumn {
  background-color: #ff0000 !important; }

.container-table th {
  font-weight: bolder !important;
  border-color: black !important;
  background-color: #ddebf7 !important; }

.container-table .summaryColumn {
  font-weight: bolder !important; }

.container-table .wrapper-buttons {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 12px; }

.ExcelButton_container__3oW5j {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }

.ExcelButton_subtitle__2GjN9 {
  margin: 0;
  padding: 0; }

