.container-table {
  :global {

    table,
    td,
    .ant-table-container {
      border-color: black !important;
    }

    thead,
    tbody {
      .ant-table-cell {
        padding: 4px;
      }
    }

    .table-events {
      .ant-table-row-level-0 td {
        background-color: #ffc48c !important;
      }

      .ant-table-row-level-1 td {
        background-color: #effab4 !important;
      }

      .ant-table-cell-with-append {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }

    .fizColumn {
      background-color: #ddebf7 !important;
    }

    .refusalColumn {
      background-color: #9bc2e6 !important;
    }

    .ant-table-thead .yrColumn {
      color: #ff0000 !important;
      background-color: white !important;
    }

    .ant-table-tbody .yrRow .yrColumn {
      background-color: #ff0000 !important;
    }

    th {
      font-weight: bolder !important;
      border-color: black !important;
      background-color: #ddebf7 !important;
    }

    .summaryColumn {
      font-weight: bolder !important;
    }
  }

  .wrapper-buttons {
    display: flex;
    gap: 12px;
    margin-top: 12px;
  }
}