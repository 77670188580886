.form {
  display: flex;
  justify-content: space-around;
  gap: 16px;

  &__group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}