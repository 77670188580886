.error {
  color: #ff4d4f;
  font-size: 0.9em;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input-error {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.table {
  :global {
    table,
    td,
    .ant-table-container {
      border-color: black !important;
    }
    .fizColumn {
      background-color: #e1efda !important;
    }
    .ant-table-thead .yrColumn {
      color: #ff0000 !important;
      background-color: white !important;
    }
    .ant-table-tbody .yrRow .yrColumn {
      background-color: #ff0000 !important;
    }
    th {
      font-weight: bolder !important;
      border-color: black !important;
      background-color: #e1efda !important;
    }
    .summaryColumn {
      font-weight: bolder !important;
    }
  }
}

.error {
  color: #ff4d4f;
  font-size: 0.9em;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input-error {
  border: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
