.container-selectors {
  display: flex;
  gap: 12px;

  .ant-form-item {
    flex: 1;
  }
}

.container-buttons {
  display: flex;
  gap: 8px;
}