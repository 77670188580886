@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.logo {
  float: left; }

.color-white {
  color: #fff; }

.map-wrap {
  height: 400px; }

.modal__fullwidth {
  width: 90% !important; }

.ymaps-2-1-72-map {
  height: 400px !important; }

.link {
  color: #40a9ff;
  cursor: pointer; }

.map-wrapper {
  margin: 20px 0;
  height: 80vh; }

#root .map-wrapper .ymaps-2-1-72-map {
  height: 80vh !important; }

body .ant-modal-body .ant-checkbox-group-item {
  padding: 5px;
  width: 48%;
  overflow: hidden;
  flex-grow: 1; }

.custom_checkbox_group {
  display: flex;
  flex-wrap: wrap; }
  .custom_checkbox_group .ant-checkbox + span {
    white-space: nowrap;
    display: inline-block;
    width: 90px; }

.button_bind button {
  width: 100%; }

.ant-collapse {
  min-height: 200px; }

.media .ant-radio-group,
.media label {
  width: 100%; }

.media .item {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  position: relative; }
  .media .item .anticon {
    position: absolute;
    font-size: 18px;
    top: 15px;
    right: 10px; }

.notification__status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto; }

.notification__status--success {
  border: 1px solid #006900;
  background-color: #00f30780; }

.notification__status--warning {
  border: 1px solid #ffc107;
  background-color: #ffc107; }

.notification__status--danger {
  border: 1px solid #b71c1c;
  background-color: #f44336; }

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #001529 !important; }

.ant-menu.ant-menu-dark .ant-menu-item-active {
  background-color: #40a9ff !important; }

thead .text-align-bottom {
  vertical-align: bottom; }

.gosuslugy .ant-input-number-handler-wrap {
  display: none; }

.gosuslugy tbody .ant-table-cell,
.gosuslugy thead .ant-table-cell {
  padding: 5px 5px; }

.gosuslugy th,
.gosuslugy td {
  box-sizing: border-box !important; }

.gosuslugy .table-3 .ant-table-row-level-0 td {
  background-color: #ffc48c !important; }

.gosuslugy .table-3 .ant-table-row-level-1 td {
  background-color: #effab4 !important; }

.gosuslugy .table-3 .ant-table-cell-with-append {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.ant-layout-sider-trigger {
  display: none; }
